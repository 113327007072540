// Sablona - vsechny stranky

/**************************
* DEFINICE PROMĚNNÝCH
**************************/

$max-width: 1280px;

$link-focus-bg: #303030;
$link-focus-text: #ffffff;

/*************************/

/**************************
* OBECNÉ
**************************/

html,
body {
	background-color: $hlavniobsahpozadi;
}

body {
	@extend %reset;
	text-align: center;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.expandable):not(.menu-link):focus {
	border-radius: 3px;
	box-shadow: 0 0 0 2000px $link-focus-bg inset, 0 0 0 3px $link-focus-bg !important;
	color: $link-focus-text !important;
	outline: 0;
}


/*************************
* HLAVNÍ OBSAH
*************************/

.centrovany {
	@extend %sf;
	box-sizing: border-box;
	width: 100%;
	max-width: $max-width;
	margin: 0 auto;
	text-align: left;
	padding-left: 3%;
	padding-right: 3%;

	@include bp("> 1360px") {
		padding-left: 0;
		padding-right: 0;
	}

	&.uzsi {
		max-width: 700px;
	}
}

.section {
	padding-top: 15px;
	padding-bottom: 25px;

	@include bp("> 800px") {
		padding-top: 34px;
		padding-bottom: 54px;		
	}

	&--narrow {
		@include bp("> 1024px") {
			padding-left: 6%;
			padding-right: 6%;
		}

		@include bp("> 1280px") {
			padding-left: 11%;
			padding-right: 11%;
		}
	}

	.section-halves {
		@include bp("> 600px") {
			display: table;
			width: 100%;
			table-layout: fixed;
		}
	}

	.section-half {
		margin-bottom: 25px; 
		
		@include bp("> 600px") {
			display: table-cell;
			vertical-align: top;
			padding-right: 4%;

			+ .section-half {
				padding-left: 4%;
				padding-right: 0;
			}
		}
	}
}


#stred,
#vpravo,
#vlevo {
	@extend %border-box;
	width: 100%;
	float: none;
	box-sizing: border-box;
	margin-bottom: 30px;
}

#vpravo,
#vlevo {
	word-wrap: break-word;
}

#stranka #stred {
	padding: 0 4% 40px 4%;
}

#stranka #obalcelek {
	background-color: #ffffff;
}

/*************************************
* TABULKOVÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

#stranka #telo {
	padding-top: 30px;
	padding-bottom: 40px;
}

@include bp(">= 800px") {
	#celek::after {
		content: normal; 
	}

	#telo {
		display: table;
		border-spacing: 0;
		width: 100%;

		#stranka & {
			padding-top: 50px;
			padding-bottom: 70px;
		}

		#stred,
		#vlevo {
			display: table-cell;
			vertical-align: top;
		}

		#vlevo {
			width: ratio(1280px, 468px);
			padding: 0 2.75%
		}

		#stred {
			width: auto;
			padding-left: 6.25%;
			padding-right: 20px;
			border-left: 3px $linky solid;
		}

		#stranka & {
			direction: rtl;

			> * {
				direction: ltr;
			}
		}
	}
}


/*************************************
* FLOATOVANÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

// #telo,
// #stred {
// 	@extend %sf;
// }

// @include bp(">= 800px") {
// 	#stred {
// 		float: right;
// 		width: ratio($max-width, 740px);
// 	}

// 	#vlevo {
// 		width: ratio($max-width, 440px);
// 	}

// 	#vlevo {
// 		float: left;
// 	}
// }


/************************
* PRVKY STRÁNKY
************************/

.obalcesta {
	background-color: $zahlavipozadi;
}

.cesta {
	font-size: rem(14px);
	padding: 16px 2% 16px 2%;
	position: relative;
	color: $zahlavitext;

	strong {
		font-weight: normal;
	}

	@include link {
		color: $zahlavitext;
	}
}

.box {
	padding: 0 15px 10px 15px;
	background: #cccccc;
	margin: 0 0 15px 0;
}

.wrapper-kalendar {
	background-color: $kalendarakcipozadi;

	h2.cvi {
		color: $akcehlavninadpis;
	}
}

.dalsi {
	font-size: rem(16px);
	margin: 15px 0 0 0; 
	padding: 5px 0 0 0;
	text-align: center;
	text-transform: uppercase;

	a,
	.btn {
		box-sizing: border-box;
		display: block;
		border-radius: 4px;
		min-width: 242px;
		padding: 12px 3em 10px 3em;
		background: $tlacitkapozadi url(images/more.svg) no-repeat 100% 50%;
	}

	@include link {
		color: $tlacitkatext;
		text-decoration: none;
	}

	.wrapper-kalendar & {
		a {
			background-color: $akcetlacitkapozadi;
		}

		@include link {
			color: $akcetlacitkatext;
		}
	}

	@include link-over {
		text-decoration: underline;
	} 


	&-inline {
		margin: 15px 0 0 0; 
		padding: 5px 0 0 0;
		text-align: left;

		a {
			padding-right: 3em;
			background: transparent url(images/more-inline.svg) no-repeat 100% 50%;		
		}

		@include link {
			text-decoration: underline;
		}
	}

	@include bp("> 600px") {	 
		//text-align: right;

		a,
		.btn {
			display: inline-block;
		}
	}
}


/************************
* ZÁPATÍ
************************/

#obalnadpatou {
	background: $vysokapatapozadi;
	overflow: hidden;
}

#nadpatou {
	color: $vysokapatatext;

	@include link {
		color: $vysokapataodkazy;
	}

	h2.cvi {
		color: $vysokapatanadpisy;
		font-size: rem(40px);

		@include bp("> 600px") {
			text-align: left;
		}
	}

	.pata-cols {
		margin: 0 auto;
		padding: 20px 0;
		
		.pata-col {
			margin-bottom: 30px;
		}
	}

	@include bp("> 900px") {
		margin-left: -25px;
		margin-right: -25px;

		.pata-cols {
			width: 100%;
			table-layout: fixed;
			border-spacing: 25px 0;
			display: table;
			
			.pata-col {
				padding: 25px 0;
				display: table-cell;
				vertical-align: top;
			}
		}
	}
}


#obalpata {
	background-color: $patapozadi;
	min-width: 320px;
}

#pata {
	text-align: center;
	padding: 22px 16px 28px;
	color: $patatext; 
	box-sizing: border-box;
	font-size: rem(14px);

	*{
		line-height: 2.2;
		border: 0;
	}

	li + li::before{
		content: "\|";
		margin: 0 8px 0 0;
	}

	.partWebmaster1::before{
		content: "\|";
		margin: 0 10px 0 0;
	}

	@include link {
		color: $pataodkazy;
		text-decoration: underline;
	}

	@include link-over {
		text-decoration: none;
	}
	
	.patalogo {
		padding: 1px 6px;
		margin-right: 11px;
	}

	#pocitadlo{
		margin: 0!important;
		padding-bottom: 0!important;
	}

	.webmaster,
	.inline {
		display: inline-block;
		margin: 0;
		padding: 0 0 0 3px;

		li {
			padding: 0 5px;

			a {
				margin: 0 0 0 1px;
			}
		}
	}

	.webmaster {
		display: block;

		#stranka &{
				margin-top: 2px;
			}

		.partWebmaster {
			display: inline-block;
			margin-left: 5px;
		}
	}

	ul.inline li {
		display: inline-block;
	}
}

#obalmbannery{
	min-width: 320px;
}

@include bp("< 600px"){
	#pocitadlo{
		li{
			display:block!important;

			+ li::before{
				content: "";
				margin:0;
			}	
		}
	}
}

@include bp("< 500px"){
	.partWebmaster1 {
		display: block!important;
		margin-top: 2px;

		&::before{
			content: ""!important;
			margin:0!important;
		}	
	}	
}

#unpobtekane {
	margin-left: 0 !important;
}


#uvod {
	hr {
		color: $upozorneninadpisy;
		background-color: $upozorneninadpisy;
	}
}
