
/*************************
* ZÁHLAVÍ
*************************/


/*************************
* HLAVA
*************************/
 
#obalhlava {
	position: relative;
	z-index: 13;
	background-color: $zahlavipozadi;
	color: $zahlavitext;

	@include link {
		color: $zahlavitext;
	}
}

#hlava {
	@extend %sf;
	clear: both;
	position: relative;
	z-index: 3;
	padding-top: 14px;
}

#znak {
	margin: 0;
	max-width: 77px;
	height: auto;
	display: none;
}

.hlava-cols {
	.hlava-col {
		margin: 10px 4% 20px 4%;

		+ .hlava-col {
			text-align: center;		
		}
	}

	@include bp("> 600px") {
		//display: table;
		//table-layout: fixed;
		width: 100%;
		
		&::after {
			content: "";
			display: table;
			width: 100%;
			height: 0;
			clear: both;
		}

		.hlava-col {
			//display: table-cell;
			vertical-align: top;
			float: left;
			margin: 0;
			padding: 0 0 20px 0;

			+ .hlava-col {
				float: right;
				text-align: right;	
			}
		}
	}
}


#nazev {
	text-align: center;
	position: relative;

	a {
		z-index: 30;
		display: inline;
		text-decoration: none !important;
		outline-width: 0;
		font-size: 0;

		img {
			font-size: rem(16px);
			line-height: 1;
		}

		&::after {
			content: "";
			@extend %fill;
		}
	}
}

@include bp("> 600px") {
	// #znak,
	// #nazev,
	// #nazev a,
	// h1.cvi {
	// 	float: left;
	// }

	#znak {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
	}

	#nazev {
		text-align: left;
	}
}


/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/

#obrazek {
	display: none;
}

#obalobrazek {
	@extend %sf;
}

@include bp("> 600px") {
#obalobrazek {
	display: block;
	clear: both;
	background: transparent url(images/bg-main.jpg) no-repeat 50% 50%;
	background-size: cover; 
	max-width: 1920px;
	margin: 0 auto;
	position: relative;

	.wrapper.wrapper-uvod::after  {
		padding-bottom: 0!important;
		margin-bottom: 0px;
	}
}

#obrazek {
	position: relative;
	display: block;
	z-index: 20;
	#blender {
		z-index: 1;
		position: relative;
		height: 512px;
		padding-bottom: 0!important;

		div[id] {
			@include fill;
			width: auto !important;
			height: auto !important;
			background-size: cover !important;
			overflow: visible;
		}

		.prolinani-e {
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			display: table;
			height: 100%;
		}

		.prolinani-e-texty {
			font-family: $font;
			color: #ffffff;
			position: relative;
			width: 100%;
			vertical-align: middle;
			// display: none;
			box-sizing: border-box;
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: rem(34px);
			padding: .25em 0;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 55px;
			z-index:5;
			background-color: rgba(#000000, .5);
			text-align: center;
			visibility: hidden;
			@include bp("> 900px") {
				font-size: rem(40px);
				height: 70px;
			}
		}

		.prolinani-e-nazev,
		.prolinani-e-popisek {
			display: inline-block;
			line-height: 1;
			z-index: 200;
		}

		.prolinani-e-obrazek {
			box-sizing: border-box;
			width: 100%!important;
			overflow: hidden;
			height: 512px;
			position: absolute;
			padding-bottom: 0!important;

			a,
			span {
				@include fill;
				background-size: cover;
			}
		}

		@include bp("< 1360px"){
			height: 0;
			padding-bottom: 31.4612% !important;
			.prolinani-e-obrazek {
				height: 0;
				padding-bottom: 31.4612% !important;
			}
		}
	}
}

// .popisekObal{
//     box-sizing: border-box;
//     position:absolute;
//     font-size: rem(40px);
//     padding: .25em 0 .25em;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     height: 70px;
//     z-index:5;
//     background-color: rgba(#000000, .5);
//     color: #ffffff;
//     text-align: center;
// }

#administraceobrazky {
	position: absolute;
	left: 50%;
	top: 20px;
	transform: translate(-50%, 0);
	z-index: 20; 
}

// #obrlinky {
// 	position: absolute;
// 	z-index: 2;
// 	bottom: 14px;
// 	right: 25px;

// 	ul {
// 		margin: 0;
// 		padding: 0;
// 		list-style-type: none;

// 		li {
// 			display: inline;
// 			padding: 0;
// 			margin: 0 0 0 14px;
// 		}
// 	}

// 	a {
// 		display: inline-block;
// 		font-size: 0;
// 		line-height: 0;
// 		width: 17px;
// 		height: 17px;
// 		border-radius: 100px;
// 		background-color: #ffffff;
// 		box-shadow: 1px 1px 0 rgba(#000000, .85) inset;

// 		&.imagesBlender_link_active {
// 			background-color: red;
// 		}
// 	}
// }
}



#lista {
	@extend %sf;
	text-align: right;
	padding: 0;
	position: relative;

	ul {
		@extend %reset-list;
		position: relative;

		&.inline {
			display: inline;
		}
	}

	li {
		margin: 0;
		white-space: nowrap;

		&.skip {
			position: absolute;
			display: block;
			top: 0 !important;
			right: 0 !important;
			max-width: 100%;
			padding: 1px;
			border: 0;

			a {
				text-align: center;
				border: 0;
				position: absolute;
				right: 0;
				top: -500px;
				z-index: 0;
				padding: 0 2px;
				background-color: #ffffff;
				color: #000000;
			}
			
			a:focus,
			a:active {
				z-index: 10 !important;
				position: absolute !important;
				right: 10px;
				top: 5px;
			}
		}
	}
}
