/*oE (vsechny stranky)*/
$zahlavipozadi: #C2E812;
$zahlavitext: #000000;
$nazev: #0F5F91;
$dodatek: #000000;
$zahlaviodkazy: #000000;
$vyhledavaniobrys: #0F5F91;
 
$hlavninadpisy: #0F5F91;
 
$menupozadi: #D80D61;
$menuzvyraznenipozadi: #FFFFFF;
$menutlacitkapozadi: #FECB00;
$menutlacitkatext: #303030;
$menuzvyraznenitext: #303030;
 
$upozornenipozadi: #C2E812;
$upozorneninadpisy: #055591;
$upozornenitext: #303030;
$upozorneniodkazy: #B5034D;
 
$aktuality1nadpisy: #D80D61;
$linky: #FECB00;
$bileplochyodkazy: #0F5F91;
 
$deskalinky: #FECB00;
 
$kalendarakcipozadi: #F5F5F5;
$akcehlavninadpis: #0F5F91;
$akcenadpisy: #D80D61;
$kalendarakcitext: #000000;
$akcedoplnkovytext: #000000;
$odkazyTabulekOstatni: #303030;
$odkazyTabulekAktualni: #333333;
$bunkyTabulekOstatni: #FECB00;
$bunkyTabulekAktualni: #C2E812;
$akcetlacitkapozadi: #FECB00;
$akcetlacitkatext: #000000;
$akceodkazy: #0F5F91;
 
$formularepozadi: #FFFFFF;
$formularetext: #233323;
$formularenadpis: #0F5F91;
$polepozadi: #FFFFFF;
$poletext: #425542;
$poleobrysy: #D80D61;
 
$tlacitkapozadi: #C2E812;
$tlacitkatext: #000000;
 
$vysokapatapozadi: #D3085C;
$vysokapatatext: #FFFFFF;
$vysokapataodkazy: #FFFFFF;
$vysokapatanadpisy: #FEF819;
$vysokapatasymboly: #C2E812;
 
$patapozadi: #C2E812;
$patatext: #000000;
$pataodkazy: #055582;
